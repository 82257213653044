<div class="container">
  <div class="flex">
    <div>
      <h2>{{ title }}</h2>
      <p>{{ message }}</p>
    </div>
    <div
      (click)="cancel()"
      (keydown.enter)="cancel()"
      class="close"
      tabindex="0"
    >
      <mat-icon class="close-icon">close</mat-icon>
    </div>
  </div>
  <div class="buttons">
    <button (click)="cancel()" class="cancel">Cancel</button>
    <button (click)="navigateTo()" class="generate" mat-dialog-close>
      {{ textButton }}
    </button>
  </div>
</div>
