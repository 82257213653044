import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  MAT_DIALOG_DATA,
  MatDialogClose,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { LocalStorageService } from '@services/client/local-storage/local-storage.service';
import { DataService } from '@services/client/data/data.service';
import { GuardsServiceService } from '@services/core/guards/guuads-service.service';
import { SafeAny } from '@src/types/safeAny';

@Component({
  selector: 'app-dialog-login',
  templateUrl: './dialog-login.component.html',
  styleUrls: ['./dialog-login.component.scss'],
  standalone: true,
  imports: [MatDialogClose, MatIcon],
})
export class DialogLoginComponent {
  title: string;
  message: string;
  navigateToLink: string;
  textButton: string;
  user: SafeAny;

  constructor(
    public router: Router,
    private localStorageService: LocalStorageService,
    public dialogRef: MatDialogRef<DialogLoginComponent>,
    private dataService: DataService,
    private guardsServiceService: GuardsServiceService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title?: string;
      message?: string;
      navigateToLink?: string;
      textButton?: string;
      user?: SafeAny;
    },
  ) {
    this.title = data && data.title ? data.title : 'Would you like to log in?';
    this.message =
      data && data.message ? data.message : 'Login to leave a comment.';
    this.navigateToLink =
      data && data.navigateToLink ? data.navigateToLink : '/sign-in';
    this.textButton = data && data.textButton ? data.textButton : 'Login';
    this.user = data?.user;
  }

  navigateTo() {
    this.dialogRef.close();
    if (this?.user) this.localStorageService.setUserSocial(this.user);
    const socialUser = this.localStorageService.getUserSocial();
    const isSocial = this.localStorageService.getIsSocial();
    this.router.navigate([this.navigateToLink]).then(() => {
      this.localStorageService.setUserSocial(socialUser);
      this.localStorageService.setIsSocial(isSocial);
      this.dataService.setLoginDataWithOutPassword(socialUser?.email);
    });
  }

  cancel() {
    this.dialogRef.close();
    this.guardsServiceService.logOut();
    this.localStorageService.removeUserSocial();
    this.localStorageService.removeIsSocial();
    this.localStorageService.removeCreateUserInfo();
    this.dataService.clearLoginData();
    this.router.navigate(['/']).then();
  }
}
